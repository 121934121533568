import './App.css';

import React, { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from "@material-ui/core/Box";

    

const NowPlaying = () => {
  const [nowplayingData, setNowPlaying] = useState([]);


  async function loadnowplaying() {
    var uuid = require("uuid");
    var id = uuid.v4();
    const response = await fetch(`https://megahit.live/assets/json/getnpassets.php?random=${id}`);
    var nowplaying = await response.json();
    setNowPlaying(nowplaying);
}

  useEffect(() => {




      loadnowplaying();

  // the channel does not exist
  const socket = require("socket.io-client")("https://catalina.xseu.net", {
    path: "/3391/socket.io",
    rejectUnauthorized: false // WARN: please do not do this in production
  });

     
     // I expect this event to be triggered
     socket.on('FileChangedMEGAHIT', function(err){
      loadnowplaying();
         
     });


     window.addEventListener('focus', loadnowplaying);
 }, [])
return (
  
  <div className="np-info">
    
      <div className="np-info-container">
          <div className="songinfo">
                 <Avatar 
         src={nowplayingData?.song_artwork?.replace("500x500", "50x50") || ""}
         alt={nowplayingData?.song_title}
         style={{ width: 50, height: 50, borderRadius: 10, marginTop: 10 }} />
               <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingTop: 15}}>
               <Box component={'span'} class="now-on-air-artist">{nowplayingData.song_artist}</Box>
              <Box component={'span'} class="now-on-air-title">{nowplayingData.song_title}</Box>
        </div>
      </div>
    </div>

      
  
  </div>
);
}

export default NowPlaying;



